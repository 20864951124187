import "liquid-ajax-cart";

import Alpine from 'alpinejs'
import AlpineCollapse from '@alpinejs/collapse'
import AlpineFocus from '@alpinejs/focus'
import AlpineMorph from '@alpinejs/morph'
import AlpineGlobals from '../js/alpine/index.js'
import helpers, { hasBodyClass } from '../js/helpers.js'
import Lettering from '../js/lettering.js'

import gsap from 'gsap';
import Flip from 'gsap/Flip';

import Choices from 'choices.js';
import 'choices.js/public/assets/styles/choices.css'; // Import the CSS

//Swiper.use([Autoplay, Controller]);
//import('../js/Flip.min.js')
gsap.registerPlugin(Flip);
const hero = document.querySelector('.hero-slideshow-background');

 function setMobileMenuHeight() {
  const navTrigger = document.querySelector("#mobile-nav-trigger");
  const triggerRect = navTrigger.getBoundingClientRect();

  const triggerOffsetTop = triggerRect.top + window.scrollY;
  const triggerHeight = triggerRect.height;

  // Get computed styles to check for margins
  const computedStyles = window.getComputedStyle(navTrigger);
  const marginTop = parseFloat(computedStyles.marginTop);
  const marginBottom = parseFloat(computedStyles.marginBottom);

  // Calculate the total offset including margins
  const totalOffset = triggerOffsetTop + triggerHeight + marginTop + marginBottom;

  // Set the totalOffset as a CSS variable
  document.documentElement.style.setProperty('--offset-variable', `${totalOffset}px`);
  console.log('total offset: '+totalOffset);
}

setMobileMenuHeight();
window.addEventListener("resize", setMobileMenuHeight);
window.addEventListener("orientationchange", setMobileMenuHeight);
window.addEventListener("load", setMobileMenuHeight);

document.querySelector("#mobile-nav").addEventListener("click", setMobileMenuHeight);

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.select__select');
  elements.forEach(el => {
    new Choices(el, {
      placeholder: true,
      searchEnabled: true,
      removeItemButton: true // Enables the ability to remove selected items
    });
  });
});

if(hero) {
  const swiper1 = new Swiper(hero, {
    slidesPerView: 1,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    on: {
      slideChange: () => updateHeadlines(swiper1.activeIndex)
    }
  });
  
  const swiper2 = new Swiper('.hero-slideshow-highlights', {
    slidesPerView: 1,
    allowTouchMove: false,
    loop: true,
  });
  
 
  
  // Bind swipers
  function bindSwipers(...swiperList) {
    swiperList.forEach((swiper, index) => {
      swiper.on('slideChange', function () {
        const otherSwipers = swiperList.filter((_, i) => i !== index);
        otherSwipers.forEach(otherSwiper => {
          otherSwiper.slideTo(swiper.realIndex, swiper.params.speed, false);
        });
      });
    });
  }
  bindSwipers(swiper1, swiper2);
  
  // Select list items
  const list = document.querySelector('.hero-slideshow-headings ul');
  const listItems = document.querySelectorAll('.hero-slideshow-headings li');
  
  // Function to shuffle list items
  function updateHeadlines(activeIndex) {
    // Get the current state of the list items
    const state = Flip.getState(listItems);
  
    // Move the active headline to the top
    list.prepend(listItems[activeIndex]);
  
    // Animate the FLIP state
    Flip.from(state, {
      duration: 0.3,
      ease: 'sine.out'
    });
  }
  
  // Initial setup
  updateHeadlines(swiper1.activeIndex);

}

const igSwiper = new Swiper('.instagram-slides .swiper-container', {
  slidesPerView: 1,
  loop: true,
  centeredSlides: true,
  autoplay: true,
  breakpoints: {
    768: {
      slidesPerView: 4,
      autoplay: false,
      freemode: true
    },
  }
});

const slideshowSwiper = new Swiper('.slideshow .swiper-container', {
  slidesPerView: 1,
  loop: true,
  centeredSlides: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});


 
 const promoBarContainer = document.querySelector("#site-promo-bar .text-center");
const promoBar = promoBarContainer.querySelector("ul");
const slides = promoBar.querySelectorAll("li");

if (slides.length > 1) {
  // Add Swiper wrapper and slide classes
  promoBar.classList.add('swiper-wrapper');
  slides.forEach(slide => {
    slide.classList.add('swiper-slide');
  });

  const nextButton = document.createElement('div');
  nextButton.classList.add('swiper-button-next');
  promoBarContainer.appendChild(nextButton);

  const prevButton = document.createElement('div');
  prevButton.classList.add('swiper-button-prev');
  promoBarContainer.appendChild(prevButton);

  // Initialize Swiper
  const swiper = new Swiper(promoBarContainer, {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
}

let toggleSubNav;

document.querySelectorAll('.header-menu li a.parent').forEach(function(element) {
    element.addEventListener('focus', handleFocusClickTouchEnd);
    element.addEventListener('click', handleFocusClickTouchEnd);
    element.addEventListener('touchend', handleFocusClickTouchEnd);

    element.addEventListener('mouseenter', handleMouseEnterMove);
    element.addEventListener('mousemove', handleMouseEnterMove);

    element.addEventListener('mouseleave', handleMouseLeave);
});
document.querySelectorAll('.header-menu li a:not(.parent)').forEach(function(element) {
    element.addEventListener('mouseenter', function() {
        dropDown(0, this);
    });
});
document.querySelector('.header-menu').addEventListener('mousemove', function(event) {
    if (!event.target.closest('a.parent')) {
        dropDown(0, this);
    }
});

document.querySelectorAll('#global-menus .dropdown-menu').forEach(function(element) {
    element.addEventListener('mousemove', function() {
        clearTimeout(toggleSubNav);
    });

    element.addEventListener('mouseleave', function() {
        clearTimeout(toggleSubNav);
        toggleSubNav = setTimeout(function() {
            dropDown(0, this);
        }, 150);
    });
});

function handleFocusClickTouchEnd(e) {
    clearTimeout(toggleSubNav);
    let myID = this.getAttribute('data-menu');
    dropDown(myID, this);
    e.stopPropagation();
}

function handleMouseEnterMove() {
    let myID = this.getAttribute('data-menu');
    clearTimeout(toggleSubNav);
    let myParent = this;
    if (!this.classList.contains('active')) {
        toggleSubNav = setTimeout(function() {
            dropDown(myID, myParent);
        }, 150);
    }
}

function handleMouseLeave() {
    let myID = this.closest('li').id;
    if (!this.closest('li').classList.contains('active')) {
        clearTimeout(toggleSubNav);
    }
}

function dropDown(myID, triggerElement) {
    clearTimeout(toggleSubNav);
    if(myID === 0){
      document.querySelectorAll('.header-menu a.parent').forEach(function(element) {
          element.classList.remove('active');
      });
      document.querySelectorAll('#global-menus .dropdown-menu').forEach(function(element) {
          if (element.classList.contains('active')) {
              element.classList.remove('active');
          }
      });
    } else{
      if (document.querySelector('#global-menus .dropdown-menu#' + myID)) {
          document.querySelectorAll('#global-menus .dropdown-menu:not(#' + myID + ')').forEach(function(element) {
              element.classList.remove('active');
          });
          document.querySelectorAll('.header-menu a.parent:not([data-menu="' + myID + '"])').forEach(function(element) {
              element.classList.remove('active');
          });
          //document.querySelector('.header-menu a.parent[data-menu="' + myID + '"]').classList.add('active');
          //document.querySelector('#global-menus .dropdown-menu#' + myID).classList.add('active');
          let activeDropdown = document.querySelector('#global-menus .dropdown-menu#' + myID);
          let sectionHeaderHeight = document.querySelector('.section-header').offsetHeight;
          let topOffset = sectionHeaderHeight - parseFloat(getComputedStyle(document.documentElement).fontSize);
          activeDropdown.style.top = `${topOffset}px`;
          
          let parentOffsetLeft = triggerElement.getBoundingClientRect().left;
          activeDropdown.style.paddingLeft = `${parentOffsetLeft}px`;
          
          document.querySelector('.header-menu a.parent[data-menu="' + myID + '"]').classList.add('active');
          activeDropdown.classList.add('active');
      } 
    }

}


let toggleSubMenu;

// Add event listeners to sub-parent links
document.querySelectorAll('.dropdown-menu .primary li a.sub-parent').forEach(function(element) {
    element.addEventListener('focus', handleSubFocusClickTouchEnd);
    element.addEventListener('click', handleSubFocusClickTouchEnd);
    element.addEventListener('touchend', handleSubFocusClickTouchEnd);

    element.addEventListener('mouseenter', handleSubMouseEnterMove);
    element.addEventListener('mousemove', handleSubMouseEnterMove);

    element.addEventListener('mouseleave', handleSubMouseLeave);
});

// Add event listeners to non-sub-parent links
document.querySelectorAll('.dropdown-menu .primary li a:not(.sub-parent)').forEach(function(element) {
    element.addEventListener('mouseenter', function() {
        showSubMenu(0);
    });
});

// Add event listeners to sub-menus
document.querySelectorAll('.dropdown-menu .sub-menu').forEach(function(element) {
    element.addEventListener('mousemove', function() {
        clearTimeout(toggleSubMenu);
    });

    /*element.addEventListener('mouseleave', function() {
        clearTimeout(toggleSubMenu);
        toggleSubMenu = setTimeout(function() {
            showSubMenu(0);
        }, 150);
    });*/
});

// Add mousemove event listener to the entire dropdown menu but exclude sub-parent links
document.querySelector('.dropdown-menu').addEventListener('mousemove', function(event) {
    if (!event.target.closest('a.sub-parent')) {
        showSubMenu(0);
    }
});

function handleSubFocusClickTouchEnd(e) {
    clearTimeout(toggleSubMenu);
    let subID = this.getAttribute('data-sub-menu');
    showSubMenu(subID);
    e.stopPropagation();
}

function handleSubMouseEnterMove() {
    let subID = this.getAttribute('data-sub-menu');
    clearTimeout(toggleSubMenu);
    if (!this.classList.contains('active')) {
        toggleSubMenu = setTimeout(() => {
            showSubMenu(subID);
        }, 150);
    }
}

function handleSubMouseLeave() {
    let subID = this.closest('li').id;
    if (!this.closest('li').classList.contains('active')) {
        clearTimeout(toggleSubMenu);
    }
}

function showSubMenu(subID) {
    clearTimeout(toggleSubMenu);
    if(subID === 0){
      document.querySelectorAll('.dropdown-menu a.sub-parent').forEach(element => {
          element.classList.remove('active');
      });
      document.querySelectorAll('.dropdown-menu .sub-menu').forEach(element => {
          if (element.classList.contains('active')) {
              element.classList.remove('active');
          }
      });
    } else if (document.querySelector('.dropdown-menu .sub-menu#' + subID)) {
        document.querySelectorAll('.dropdown-menu .sub-menu:not(#' + subID + ')').forEach(element => {
            element.classList.remove('active');
        });
        document.querySelectorAll('.dropdown-menu a.sub-parent:not([data-sub-menu="' + subID + '"])').forEach(element => {
            element.classList.remove('active');
        });

        let activeSubMenu = document.querySelector('.dropdown-menu .sub-menu#' + subID);

        document.querySelector('.dropdown-menu a.sub-parent[data-sub-menu="' + subID + '"]').classList.add('active');
        activeSubMenu.classList.add('active');
    } 
}

if (document.querySelector('#artist-about-heading')) {
  var lettering = new Lettering('#artist-about-heading');
  lettering.words();
}
// Dynamic imports
hasBodyClass('product-template') && import('../js/prodify.js')

function getMinicartRecs() {
  // Collect all product IDs in the cart
  const cartItems = document.querySelectorAll('.minicart .items-list .item');
  const cartProductIds = Array.from(cartItems).map(item => item.getAttribute('data-product-id'));

  if (cartProductIds.length > 0) {
    const firstProductId = cartProductIds[0]; // Use the first product in the cart to get recommendations

    // Fetch more than 6 recommendations to account for filtering out cart items
    fetch(`/recommendations/products.json?product_id=${firstProductId}&limit=12`)
      .then(response => response.json())
      .then(data => {
        if (data.products.length > 0) {
          // Filter out products that are already in the cart
          const filteredProducts = data.products.filter(product => !cartProductIds.includes(product.id.toString()));

          // Ensure we still show up to 6 products even after filtering
          const displayedProducts = filteredProducts.slice(0, 6);

          if (displayedProducts.length > 0) {
            const recommendationsContainer = document.createElement('div');
            recommendationsContainer.classList.add('recommended-products', 'swiper-container');
            recommendationsContainer.innerHTML = `
              <span class="text-[10px] pb-2 block">You Might Also Like</span>
              <div class="swiper-wrapper pb-2 items-stretch">
                ${displayedProducts.map(product => `
                  <div class="swiper-slide self-stretch recommended-item !h-auto w-72 text-[10px] p-2 border border-gray-200 flex flex-nowrap flex-col justify-between">
                    <a href="${product.url}">
                      <img src="${product.featured_image}" alt="${product.title}">
                      <p>${product.title}</p>
                      <p>${(product.price / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</p>
                    </a>
                  </div>
                `).join('')}
              </div>
              
              <div class="swiper-button-next bg-tealCustom rounded-full "></div>
              <div class="swiper-button-prev bg-tealCustom rounded-full "></div>
            `;

            // Insert recommended products into the mini cart
            document.querySelector('.minicart-recs').innerHTML = "";
            document.querySelector('.minicart-recs').appendChild(recommendationsContainer);

            // Initialize Swiper for recommendations
            const swiper4 = new Swiper('.recommended-products', {
              slidesPerView: 3,
              spaceBetween: 6,
              navigation: {
                nextEl: '.swiper-button-next',  // Reference for the next button
                prevEl: '.swiper-button-prev',  // Reference for the previous button
              },
            });

            console.log('Fetched and filtered recommendations');
          } else {
            console.log('No recommendations available after filtering');
          }
        }
      })
      .catch(error => console.error('Error fetching recommended products:', error));
  }
}
document.addEventListener('liquid-ajax-cart:init', () => {

  getMinicartRecs();
});

document.addEventListener('liquid-ajax-cart:request-end', () => {

  getMinicartRecs();
});


const ns = 'slayed'

window.slayedNamespace = ns
window[ns] = (window[ns] || {})
window[ns].helpers = helpers

for (const [key, value] of Object.entries(helpers)) {
  window[ns].helpers[key] = value
}

// Register and initialize AlpineJS
window.Alpine = Alpine

Alpine.plugin(
  [
    AlpineCollapse,
    AlpineFocus,
    AlpineMorph
  ]
)
AlpineGlobals.register(Alpine)
Alpine.start()


